<template>
  <Header />
  <div class="page">
    <slot />
  </div>
  <ClientOnly>
    <TransitionGroup tag="div" name="show-modal">
      <Cart
        v-if="isActiveModal(cartModal)"
        @close="modalStore.toggleModal(cartModal)"
      />
      <PreOrderModal
        v-if="isActiveModal(preOrderModal)"
        @close="modalStore.toggleModal(preOrderModal)"
      />
      <SuccessfulModal
        v-if="isActiveModal(successfulModal)"
        @close="modalStore.toggleModal(successfulModal)"
      />
      <UnsuccessfulModal
        v-if="isActiveModal(unsuccessfulModal)"
        @close="modalStore.toggleModal(unsuccessfulModal)"
      />
      <AccountModal
        v-if="isActiveModal(accountModal)"
        @close="modalStore.toggleModal(accountModal)"
      />
      <SetNewPassword
        v-if="isActiveModal(setNewPasswordModal)"
        @close="modalStore.toggleModal(setNewPasswordModal)"
      />
      <AutonomicSubscriptionModal
        v-if="isActiveModal(autonomicSubscriptionModal)"
        @close="modalStore.toggleModal(autonomicSubscriptionModal)"
      />
      <ReviewModal
        v-if="isActiveModal(reviewModal)"
        @close="modalStore.toggleModal(reviewModal)"
      />
    </TransitionGroup>
    <WidgetHelper />
  </ClientOnly>
  <RenderCacheable
    :max-age="86400"
    :cache-key="[getUserLanguage.name, getHash].join('--')"
  >
    <Footer />
  </RenderCacheable>
</template>

<script setup>
import Header from "~~/modules/header/components/Header.vue";
import Footer from "~~/modules/footer/components/Footer.vue";
import {
  subscriptionModal,
  successfulModal,
  unsuccessfulModal,
  preOrderModal,
  accountModal,
  setNewPasswordModal,
  useModalStore,
  autonomicSubscriptionModal,
  cartModal,
  reviewModal,
} from "~~/modules/shared/components/modals/store/modalStore";
import PreOrderModal from "~~/modules/shared/components/modals/PreOrderModal.vue";
import SuccessfulModal from "~~/modules/shared/components/modals/components/SuccessfulModal.vue";
import AccountModal from "~~/modules/account/components/sign/AccountModal.vue";
import SetNewPassword from "~/modules/account/components/sign/SetNewPassword.vue";
import UnsuccessfulModal from "~/modules/shared/components/modals/components/UnsuccessfulModal.vue";
import WidgetHelper from "~/modules/widgetHelper/WidgetHelper.vue";
import { useStorageStore } from "~/store/storageStore";
import AutonomicSubscriptionModal from "~~/modules/shared/components/modals/AutonomicSubscriptionModal.vue";
import Cart from "~/modules/cart/components/Cart.vue";
import ReviewModal from "~~/modules/shared/components/modals/ReviewModal.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useCategoriesStore } from "~/store/categoriesStore";

const modalStore = useModalStore();
const { isActiveModal } = storeToRefs(modalStore);

const storageStore = useStorageStore();
const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);

onMounted(() => {
  if (isActiveModal.value(subscriptionModal)) {
    modalStore.toggleModal(subscriptionModal);
  }

  //for old users
  if (storageStore.storage.subscriptionModal) {
    storageStore.removeStorageState("subscriptionModal");
  }

  if (
    storageStore.storage.subscriptionViewState?.expiredAt <=
    new Date().getTime()
  ) {
    storageStore.removeStorageState("subscriptionModal");
  }
});
</script>

<style lang="scss">
.page {
  min-height: 100dvh;
}

.show-modal-enter-active,
.show-modal-leave-active {
  transition: opacity 0.3s ease;
}

.show-modal-leave-to,
.show-modal-enter-from {
  opacity: 0;
}

.item-info-enter-from {
  transform: translateY(-10px);
  opacity: 0;
}

.item-info-enter-to {
  transform: translateY(0px);
  transition: all 0.4s ease;
}

.item-info-leave-to {
  transform: translateY(-10px);
  transition: all 0.4s ease;
  opacity: 0;
}

@keyframes horizontal-shaking {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(10px);
  }
  50% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes arrow-move-forward {
  0% {
    transform: translateX(0%);
  }

  30% {
    transform: translateX(100%);
    opacity: 0;
  }

  70% {
    opacity: 0;
    transform: translateX(-150%);
  }

  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

.product__container {
  @include flex-container(column, flex-start);
  gap: 32px;

  @extend %shadow;
  background-color: white;

  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;

  padding: 24px 16px;

  @include mobile {
    border-radius: 0;
    padding: 16px;
  }
}
</style>
