<template>
  <div ref="mainInfo" class="main-info">
    <div class="main-info__wrapper">
      <ButtonBurger />
      <div class="main-info__catalog-menu">
        <ButtonCatalog @button-catalog="getButton" />
        <RenderCacheable
          :max-age="86400"
          :cache-key="[getUserLanguage.name, getHash].join('--')"
        >
          <ModalCatalog
            class="main-info__catalog"
            :style="{
              '--bottom':
                heightCatalog > bottomValue - heightHeader
                  ? -(bottomValue - heightHeader) + 'px'
                  : -heightCatalog + 'px',
            }"
            @catalog-modal="getModalCatalog"
            @height-content="getHeightContent"
          />
        </RenderCacheable>
      </div>
      <Logo
        class="main-info__logo"
        :orange="hoverLogo"
        @mouseenter="hoverLogo = true"
        @mouseleave="hoverLogo = false"
      />
      <SearchProduct />
      <ButtonLanguage />
      <NavigationMenu />
    </div>
  </div>
</template>

<script setup>
import ButtonBurger from "~~/modules/header/components/UI/ButtonBurger.vue";
import ButtonCatalog from "~~/modules/header/components/UI/ButtonCatalog.vue";
import Logo from "~~/modules/header/components/UI/Logo.vue";
import SearchProduct from "~~/modules/header/components/UI/SearchProduct.vue";
import NavigationMenu from "~~/modules/header/sections/NavigationMenu.vue";
import ModalCatalog from "~~/modules/header/sections/ModalCatalog.vue";
import { addScopedEventListener } from "~~/utils/eventListener";
import ButtonLanguage from "~/modules/header/components/UI/ButtonLanguage.vue";
import { useLanguageFilterStore } from "~/store/languageFilterStore";
import { useCategoriesStore } from "~/store/categoriesStore";

const props = defineProps({
  heightHeader: { type: Number, required: false },
});

const emits = defineEmits(["getHeightMainInfo"]);

const languageStore = useLanguageFilterStore();
const { getUserLanguage } = storeToRefs(languageStore);
const categoryStore = useCategoriesStore();
const { getHash } = storeToRefs(categoryStore);

const mainInfo = ref(null);
const buttonCatalog = ref(null);
const catalogModal = ref(null);

const heightCatalog = ref(0);
const bottomValue = ref(0);

const hoverLogo = ref(false);

function getPosition() {
  emits("getHeightMainInfo", mainInfo.value.offsetHeight);
  getPositionButton();
}

function getButton(item) {
  buttonCatalog.value = item;
}

function getModalCatalog(item) {
  catalogModal.value = item;
}

function getPositionButton() {
  bottomValue.value = document.documentElement.clientHeight;
}

function getHeightContent(value) {
  heightCatalog.value = value;
}

onMounted(() => {
  getPosition();
  addScopedEventListener(window, "resize", getPosition);
});
</script>

<style lang="scss" scoped>
.main-info {
  background-color: var(--color-grey-900);

  &__wrapper {
    @extend %width-main;

    @include flex-container(row, space-between, center);

    position: relative;

    margin: 0 auto;
    gap: 16px;
  }

  &__catalog-menu {
    @include bigMobile {
      display: none;
    }
  }

  &__catalog {
    --bottom: 0;

    position: absolute;
    top: 109%;
    bottom: var(--bottom);
    left: 0;
    z-index: 501;

    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.25);
  }

  &__button-catalog,
  &__language {
    @include bigMobile {
      display: none;
    }
  }

  &__logo {
    width: 48px;
    height: 48px;

    @include bigMobile {
      display: none;
    }
  }
}
</style>
